























import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    title: {
      type: String,
      required: true
    },
    date: {
      type: Date,
      required: true
    }
  }
})
export default class NewsDetailBanner extends Vue {
  get height() {
    switch (this.$vuetify.breakpoint.name) {
      case "sm":
        return 300;
      case "md":
        return 600;
      case "lg":
        return 600;
      case "xl":
        return 600;
      default:
        return 300;
    }
  }

  breadcrumbs = [
    {
      text: "Ana Sayfa",
      exact: true,
      to: { name: "home" }
    },
    {
      text: "Tüm Haberler",
      exact: true,
      to: { name: "news" }
    }
  ];
}
